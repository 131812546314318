import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useCourseSetList() {

  const courses_Data = ref([]);
  const category_id = ref(0);
  const category_Data  =ref([]);
  const searchQuery = ref('');
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'title', label: 'ชื่อ', sortable: false },

        { field: 'price', label: 'ราคา', sortable: false },
        { field: 'expire_day', label: 'หมดอายุวัน', sortable: false },
        
        { field: 'course_title', label: 'คอร์ส', sortable: false },

        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);


    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;

    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,selected_status], () => {
      fetchData();
    })

    watch([category_id], () => {
      fetchCourseData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-course/fetchCourseSetData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            is_active:selected_status.value,
           // branch_id:branch_id,
            order_by:'course_set.id DESC'
         
          })
          .then(response => {
          
            let data = response.data.data;
            console.log('course-set',response);
            response.data.data.forEach((data) => data.balance = data.stock_in - data.stock_out);

            Data.value = response.data.data;
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching course' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

  const fetchCourseData  = async () => { 
    courses_Data.value  = [];
    await store.dispatch('app-course/fetchData',{
        is_active:1,
        //branch_id:branch_id,
        category_id:category_id.value,
        order_by:'courses.id DESC'
      })
      .then(response => {
        courses_Data.value = response.data.data;
      })
      .catch((error) => {
      console.log('error',error);
      })
  }


  const fetchCategoryData = async ()=>{
    category_Data.value = [];
    await store.dispatch('app-course/fetchCategoryData',{
        is_active:1,
       // branch_id:branch_id,
      })
      .then(response => {
        category_Data.value = response.data.data;
      })
      .catch((error) => {
      console.log('error',error);
      })
  }

  fetchCategoryData();
  fetchCourseData();
  fetchData();

  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-course/deleteCourseSetData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }
  const resolveCourseVariant = status =>{
    if (status == 'วัสดุ') return 'dark'
    else if (status == 'บริการ') return 'warning'
    return 'light'
  }
  const resolveCourseGroupnameVariant = status =>{
    if (status == 'วัสดุ') return 'ShieldIcon'
    else if (status == 'บริการ') return 'SmileIcon'
    return 'light'
  }
    return {
        fetchData,
        tableColumns,
        courses_Data,
        category_id,
        category_Data,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_status,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveCourseVariant,
        resolveCourseGroupnameVariant
    }
}