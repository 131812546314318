<template>
    <b-sidebar
      id="course-form-sidebar"
      :visible="isCourseFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(CourseSetData)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-course-form-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{headertext}}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2 form-boder-input"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group
                label="ชื่อชุดคอร์ส"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="Data.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <validation-provider
              #default="validationContext"
              name="price"
            >
              <b-form-group
                label="ราคาขาย"
                label-for="price"
              >
                <b-form-input
                  id="price"
                  v-model="Data.price"
                  type="number"
                  step="0.01"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <validation-provider
              #default="validationContext"
              name="expire_day"
            >
              <b-form-group
                label="หมดอายุภายในกี่วัน"
                label-for="expire_day"
              >
                <b-form-input
                  id="expire_day"
                  v-model="Data.expire_day"
                  type="number"
                  step="1"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="expire_day"
              >
                <b-form-group
                  label="หมวดหมู่"
                  label-for="category_id"
                  >
                  <v-select
                  :options="category_Data"
                    v-model="category_id"
                    :get-option-label="(option) => option.title"
                    :reduce="val => val.id"
                  ></v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="m-2">
              <b-row class="mt-2">
                <b-col md="4">
                  รายการ
                </b-col>
                <b-col md="4">
                  ราคา
                </b-col>
                <b-col md="1">
                  จำนวนที่ใช้
                </b-col>
                <b-col md="3">
                  
                </b-col>
              </b-row>
            <div v-for="(item,index) in courses_Data" :key="'muti-'+index">
              <b-row class="mt-1">
                  <b-col md="4">
                  {{ item.title}}
                </b-col>
                <b-col md="4">
                  {{  item.price }}
                </b-col>
                <b-col md="1">
                  
                </b-col>
                <b-col md="3">
                  <b-button  variant="outline-info" @click="addCourse(item)">+</b-button>
                </b-col>
              </b-row>
            </div>
            <hr />
          <div v-for="(itm,index) in selecteds" :key="'selected-'+index">
            <b-row class="mt-1">
                <b-col md="4">
                {{ itm.title}}
              </b-col>
              <b-col md="4">
                {{  itm.price }}
              </b-col>
              <b-col md="1">
                  <b-form-input v-model="itm.qty" />
              </b-col>
              <b-col md="3">
                <b-button  variant="outline-danger" @click="removeCourse(index)">-</b-button>
              </b-col>
            </b-row>
          </div>
        </div>  

           
            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="status"
            >
              <b-form-group
                label="status"
                label-for="status"
              >
                <b-form-checkbox 
                  id="status"
                  v-model="Data.is_active"
                  :state="getValidationState(validationContext)"
                  trim name="check-button" 
                  switch
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  
            
          
  
         
          
  
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar,BRow, BCol, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,onUnmounted } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import useCourseSetList from './useCourseSetList'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { slugify } from '@/@fake-db/utils'
import { async } from 'q'
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormCheckbox,
      vSelect,
      BFormSelect,
      BRow, BCol,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isCourseFormSidebarActive',
      event: 'update:is-course-form-sidebar-active',
    },
    props: {
      isCourseFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      CourseSetData: {
        type: Object,
        required: true,
      },
      headertext:{
        type:String,
        required: true,
      },
  
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
   
    setup(props, { emit }) {
      const selecteds = ref([]);
      const toast = useToast();
      const userStorage = JSON.parse(localStorage.getItem('userData'));
      console.log(userStorage);
      const PATIENTS_STORE_MODULE_NAME = 'app-course';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, courseStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            courses_Data,
            category_Data,
            category_id,
        } = useCourseSetList();
      
        const addCourse = async(item)=>{
        let itm = {
            course_id:item.id,
            branch_id:userStorage.branch_id,
            created_by:userStorage.id,
            title:item.title,
            price:item.price,
            qty:1

        }
        selecteds.value.push(itm);
        }
        const removeCourse = async(index)=>{
        selecteds.value.splice(index, 1);
        }
      const blankData = {
        title:'',
        price: '',
        expire_day: '',
        branch_id:userStorage.branch_id,
        is_active:1,
        created_by: userStorage.id,
     
      }
  
      const Data = ref(JSON.parse(JSON.stringify(blankData)))
      const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
      }
      const getData = (data)=>{
        if(data){ 
          Data.value  = JSON.parse(JSON.stringify(data))
          selecteds.value = [];
          if(Data.value.course_id){
          
            console.log(course_id_array)
            data.items.forEach((value,index) => {
            selecteds.value.push({
              set_id:Data.value.id,
              course_id:value.course_id,
             // branch_id:userStorage.branch_id,
              created_by:userStorage.id,
              title:value.title,
              price:value.price,
              qty:value.qty,
              insert:false,
              });
          });
          }
          
        }
      }
      
      const onSubmit = async () => {
        //default 
        Data.value.branch_id = userStorage.branch_id;
        Data.value.created_by = userStorage.id;

        if(Data.value.is_active == true){
          Data.value.is_active = 1;
        }
        else if(Data.value.is_active == false){
          Data.value.is_active = 0;
        }
          if(Data.value.id){
            store.dispatch('app-course/updateCourseSetData', Data.value)
            .then(async () => {
              //delete all item after insert
              await store.dispatch('app-course/deleteCourseSetItemAllData',{set_id:Data.value.id})
              await selecteds.value.map(async (value)=>{
                console.log('selecteds',value);
                value.set_id = Data.value.id;
                
                await store.dispatch('app-course/addCourseSetItemData',value)
              });
              emit('refetch-data')
              emit('update:is-course-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
        }
        else{
            store.dispatch('app-course/addCourseSetData', Data.value)
            .then(async (res) => {
              const set_id = res.data.data.insertId;
              await selecteds.value.map(async (value)=>{
                value.set_id = set_id;
              await store.dispatch('app-course/addCourseSetItemData',value)
              });
              emit('refetch-data')
              emit('update:is-course-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
          }   
      }
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
  
  
      return {
        Data,
        onSubmit,
        courses_Data,
        category_id,addCourse,removeCourse,
        category_Data,
        selecteds,
        refFormObserver,
        getValidationState,
        resetForm,
        getData,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #course-form-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  